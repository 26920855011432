import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {SignUpComponent} from './components/sign-up/sign-up.component';
import {AccountConfirmComponent} from "./components/account-confirm/account-confirm.component";
import {InviteUserComponent} from './components/invite-user/invite-user.component';
import {CreateUserComponent} from './components/create-user/create-user.component';
import {AuthGuard} from './guards/auth.guard';
import { RegAuthGuard } from './guards/regauth.guard';
import { ForgotPasswordSubmitComponent } from './components/forgot-password-submit/forgot-password-submit.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'signup',
    component: SignUpComponent,
    pathMatch:'prefix'
    //canActivate:[RegAuthGuard]
  },
  {
    path: 'confirm',
    component: AccountConfirmComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'forgot-password-submit',
    component: ForgotPasswordSubmitComponent,
  },
  {
    path: 'invite-user',
    component: InviteUserComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'create-user',
    component: CreateUserComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
    // canActivate: [AuthGuard],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
