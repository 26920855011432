export const environment = {
  cognito: {
    userPoolId: 'ap-southeast-1_u1TtKt1Tc',
    userPoolWebClientId: '41kobb5j8uqd12jhk4b0skd5pu',
    region: 'ap-southeast-1'
  },
  adminResourceUrl: 'https://qa-api.buyalenovo.lk',
  customerResourceUrl: 'https://qa-api.buyalenovo.lk',

  IdentityServerConfig: {
    apiUrl: "https://qa-api.buyalenovo.lk/api",
    AuthorityUrl: 'https://qa-api.buyalenovo.lk',
    ClientId: 'LenovoWarrantyapp',
    ClientSecret: 'K7gNU3sdo+OL0wNhqoVWhr3g6s1xYv72ol/pe/Unols=',
    AppClientId: '81451b81-6741-47d8-6e3d-08da9baa1d04',
    AppClientSecret: '5$CCTlrqMfA!i!543'
  },

}
//

// export const environment = {
//   cognito: {
//     userPoolId: 'ap-southeast-1_u1TtKt1Tc',
//     userPoolWebClientId: '41kobb5j8uqd12jhk4b0skd5pu',
//     region: 'ap-southeast-1'
//   },
//   googleMapsApiKey: 'AIzaSyC5OXmdLpV_vcEOKToehTkxRTVhQCHZnrI',
//   adminResourceUrl: 'https://localhost:7046',
//   candidateResourceUrl: 'https://localhost:7046/',

//   IdentityServerConfig: {
//     //'AuthorityUrl': 'https://qalogin.galleryinvoice.com',
//     apiUrl: "https://localhost:7046/api",
//     AuthorityUrl: 'https://localhost:5001',
//     ClientId: 'seeknowapp',
//     ClientSecret: 'K7gNU3sdo+OL0wNhqoVWhr3g6s1xYv72ol/pe/Unols=',
//     AppClientId: '81451b81-6741-47d8-6e3d-08da9baa1d04',
//     AppClientSecret: '5$CCTlrqMfA!i!543'
//   },
// }


// export const environment = {
//   cognito: {
//     userPoolId: 'ap-southeast-1_u1TtKt1Tc',
//     userPoolWebClientId: '41kobb5j8uqd12jhk4b0skd5pu',
//     region: 'ap-southeast-1'
//   },
//    adminResourceUrl: 'https://api.buyalenovo.lk',
//   customerResourceUrl: 'https://api.buyalenovo.lk',

//   IdentityServerConfig: {
//     apiUrl: "https://api.buyalenovo.lk/api",
//     AuthorityUrl: 'https://api.buyalenovo.lk',
//     ClientId: 'LenovoWarrantyapp',
//     ClientSecret: 'K7gNU3sdo+OL0wNhqoVWhr3g6s1xYv72ol/pe/Unols=',
//     AppClientId: '81451b81-6741-47d8-6e3d-08da9baa1d04',
//     AppClientSecret: '5$CCTlrqMfA!i!543'
//   },
// }
