<div class="auth-layout" style="background-image: url('/assets/auth/login-backtround.png')">
    <div class="card login-form-card">
      <div class="card-body">
  
        <img class="app-logo mb-3" [ngSrc]="'/assets/logo/lenovo-logo.png'" alt="app-logo-colorful.svg" height="43"
             width="202">
        <div class="mb-4">
          <h3 class="welcome-title">Forgot Password</h3>
          <p class="welcome-message">
            Submit your email here to reset the password. You will get an reset confirmation to continue.
          </p>
        </div>
  
        <form (ngSubmit)="ToforgotPasswordSubmit()" #forgotPasswordForm="ngForm">
            <div class="form-group mb-2">
              <label for="username">User Name:</label>
              <div class="input-group">
                <span class="input-group-text">
                  <i class="fa fa-user"></i>
                </span>
                <input type="text" id="username" class="form-control" placeholder="Enter User Name" name="username" [(ngModel)]="forgotPasswordModel.userName" required minlength="3" #username="ngModel" />
              </div>
              <div *ngIf="username.invalid && (username.dirty || username.touched)" class="text-danger">
                <div *ngIf="username.errors && username.errors['required']">
                  Username is required.
                </div>
                <div *ngIf="username.errors && username.errors['minlength']">
                  Username must be at least 3 characters long.
                </div>
              </div>
            </div>
          
            <div class="form-group mb-2">
              <label for="code">Code:</label>
              <div class="input-group">
                <span class="input-group-text">
                  <i class="fa fa-lock"></i>
                </span>
                <input type="text" id="code" class="form-control" placeholder="Enter Code sent to the email" name="code" [(ngModel)]="forgotPasswordModel.code" required minlength="6" #code="ngModel" />
              </div>
              <div *ngIf="code.invalid && (code.dirty || code.touched)" class="text-danger">
                <div *ngIf="code.errors && code.errors['required']">
                  Code is required.
                </div>
                <div *ngIf="code.errors && code.errors['minlength']">
                  Code must be at least 6 characters long.
                </div>
              </div>
            </div>
          
            <div class="form-group mb-2">
                <label for="password">New Password:</label>
                <div class="input-group">
                  <span class="input-group-text">
                    <i class="fa fa-lock"></i>
                  </span>
                  <input type="password" id="password" class="form-control" placeholder="Enter New Password" name="password" [(ngModel)]="forgotPasswordModel.password" [attr.type]="passwordFieldType" required minlength="8" #password="ngModel" />
                  <button type="button" class="btn btn-outline-secondary" (click)="togglePasswordVisibility()">
                    <fa-icon [icon]="showPassword ? faEye : faEyeSlash"></fa-icon>
                  </button>
                </div>
                <div *ngIf="password.invalid && (password.dirty || password.touched)" class="text-danger">
                  <div *ngIf="password.errors && password.errors['required']">
                    Password is required.
                  </div>
                  <div *ngIf="password.errors && password.errors['minlength']">
                    Password must be at least 8 characters long.
                  </div>
                  <div *ngIf="password.value && !isValidPassword(password.value)">
                    Password must contain at least 1 number, 1 special character, 1 uppercase letter, and 1 lowercase letter.
                  </div>
                </div>
              </div>
          
            <div>
              <button type="submit" class="btn login-btn" [disabled]="forgotPasswordForm.invalid">
                Reset Password
              </button>
            </div>
          </form>
          
  
        <div>
          <p class="link">
            Do you remember your password?
            <a [routerLink]="'/login'" class="fw-medium">Login Now</a>
          </p>
        </div>
      </div>
    </div>
  </div>
  