import { Component } from '@angular/core';
import {faLock} from '@fortawesome/free-solid-svg-icons'
import { ForgotPasswordModel } from 'src/app/models/user';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  
forgotPasswordModel:ForgotPasswordModel ={} as ForgotPasswordModel;
faLock=faLock;

constructor(private router:Router, private authService:AuthService){};

public toforgotPasswordSubmit():void{
  if(this.forgotPasswordModel.userName&& this.forgotPasswordModel.userName.length>0){
    this.router.navigate(['forgot-password-submit'],
    {queryParams:{'username':this.forgotPasswordModel.userName}})

  }
}

}
