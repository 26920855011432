import {Injectable} from '@angular/core';
import * as alertifyjs from "alertifyjs";
import {ShowConfirmDialogParams} from "../models/ShowConfirmDialogParams";

@Injectable({
  providedIn: 'root'
})
export class AlertifyService {
  /** This variable defines the position of the preview. */
  previewPosition = 'top-right';

  constructor() {
  }

  /**
   * This method shows a success message to the user.
   *
   * @param message The message to show.
   */
  showSuccess(message: string) {
    alertifyjs.set('notifier', 'position', this.previewPosition)
    alertifyjs.success(message)
  }

  /**
   * This method shows a warning message to the user.
   *
   * @param message The message to show.
   */
  showWarning(message: string) {
    alertifyjs.set('notifier', 'position', this.previewPosition)
    alertifyjs.warning(message)
  }

  /**
   * This method shows an error message to the user.
   *
   * @param message The message to show.
   */
  showError(message: string) {
    alertifyjs.set('notifier', 'position', this.previewPosition)
    alertifyjs.error(message)
  }

  /**
   * This method shows a confirm dialog with the given message.
   * The successCallback will be called if the user clicks the confirm button,
   * and the errorCallback will be called if the user clicks the cancel button.
   *
   * @param options
   * @param successCallback
   * @param errorCallback
   */
  showConfirmDialog(options: ShowConfirmDialogParams, successCallback: () => void, errorCallback: () => void) {
    alertifyjs
      .confirm(
        options.message,
        successCallback,
        errorCallback
      )
      .set(options)
  }
}

