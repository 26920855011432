<div class="auth-layout">
    <div class="card login-form-card">
        <div class="card-body">
            <div class="app-logo-container d-flex mb-5">
              <img [ngSrc]="'/assets/logo/lenovo-logo.png'" alt="lenovo-logo.png" class="app-logo" height="39.347"
                    width="119">
                <div>
                    <p class="welcome-title mb-2">Welcome!</p>
                    <p class="welcome-message mb-0">Let’s Create <span class="primary">New User.</span></p>
                </div>
            </div>
            <div class="content mb-3">Account already exists, Are you continue with <span class="primary">Lenovo</span></div>
            <form action="">
                <div>
                    <button type="button" class="btn login-btn" routerLink="/create-user">Proceed</button>
                </div>
            </form>
        </div>
    </div>
</div>
