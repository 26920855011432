import { Component } from '@angular/core';
import { ForgotPasswordModel } from 'src/app/models/user';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { error } from 'jquery';
import {faEye, faEyeSlash, faLock, faUser} from '@fortawesome/free-solid-svg-icons';
import { AlertifyService } from 'src/app/services/alertify.service';

@Component({
  selector: 'app-forgot-password-submit',
  templateUrl: './forgot-password-submit.component.html',
  styleUrls: ['./forgot-password-submit.component.scss']
})
export class ForgotPasswordSubmitComponent {

  forgotPasswordModel:ForgotPasswordModel ={} as ForgotPasswordModel;
  constructor(private router:Router, private authService:AuthService, private activatedRoute:ActivatedRoute, private alertifyService:AlertifyService){};

  showPassword: boolean = false;
  passwordFieldType: string = 'password';

  protected readonly faEyeSlash = faEyeSlash;
  protected readonly faEye = faEye;
  protected readonly faUser = faUser;

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      let userName = params['username'];
      this.forgotPasswordModel.userName = userName;
      this.authService.forgotPassword(this.forgotPasswordModel)
        .then(() => {
          this.alertifyService.showSuccess('Password reset email sent successfully.');
        })
        .catch(error => {
          console.error('Error:', error.code);
          this.alertifyService.showError('Failed to send reset email. Please try again later.');
        });
    });
  }

  ToforgotPasswordSubmit(): void {
    this.authService.forgotPasswordSubmit(this.forgotPasswordModel)
      .then((resp) => {
        this.alertifyService.showSuccess('Password reset successful. Please login with your new password.');
        this.router.navigate(['/login']);
      })
      .catch((error) => {
        console.error('Error:', error.code);
        this.alertifyService.showError('Failed to reset password. Please try again later.');
      });
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
    this.passwordFieldType = this.showPassword ? 'text' : 'password';
}

isValidPassword(password: string): boolean {
  const numberRegex = /[0-9]/;
  const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;

  return (
    numberRegex.test(password) &&
    specialCharRegex.test(password) &&
    uppercaseRegex.test(password) &&
    lowercaseRegex.test(password)
  );
}

}
