import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-terms-n-conditions',
  templateUrl: './terms-n-conditions.component.html',
  styleUrls: ['./terms-n-conditions.component.scss']
})
export class TermsNConditionsComponent implements OnInit {
  constructor(private activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.close();
    localStorage.setItem('isAgreeToTC', JSON.stringify(true));
  }
}
