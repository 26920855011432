import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {LoadingService} from "./services/loading.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Lenovo Warranty Update System';

  constructor(private translateService: TranslateService, public loadingService:LoadingService)
  {
    // const browserlang = this.translateService.getBrowserLang();
    // this.translateService.setDefaultLang(typeof(browserlang));
    // this.translateService.use(localStorage.getItem('lang')||typeof(browserlang));
  }
}

