<div class="auth-layout">
    <div class="card login-form-card">
        <div class="card-body">
            <div class="app-logo-container d-flex mb-5">
              <img [ngSrc]="'/assets/logo/lenovo-logo.png'" alt="lenovo-logo.png" class="app-logo" height="39.347"
                    width="119">
                <div>
                    <p class="welcome-title mb-2">Welcome!</p>
                    <p class="welcome-message mb-0">Let’s Create <span class="primary">New User.</span></p>
                </div>
            </div>
            <div class="content mb-3">Enter your details to get started with <span class="primary">Lenovo</span></div>
            <form action="">
                <div class="form-group mb-2">
                    <label for="first-name" class="form-label" placeholder="Enter your first name here.">First Name:</label>
                    <div class="input-group">
                        <span class="input-group-text icon-preview">
                            <fa-icon [icon]="faUser"></fa-icon>
                        </span>
                        <input type="text" id="first-name" name="first-name" class="form-control" autofocus="autofocus" autocomplete="none">
                    </div>
                </div>
                <div class="form-group mb-2">
                    <label for="last-name" class="form-label" placeholder="Enter your last name here.">Last Name:</label>
                    <div class="input-group">
                        <span class="input-group-text icon-preview">
                            <fa-icon [icon]="faUser"></fa-icon>
                        </span>
                        <input type="text" id="last-name" name="last-name" class="form-control" autofocus="autofocus" autocomplete="none">
                    </div>
                </div>
                <div class="form-group mb-2">
                    <label for="email" class="form-label" placeholder="Enter your email here.">Email:</label>
                    <div class="input-group">
                        <span class="input-group-text icon-preview">
                            <fa-icon [icon]="faEnvelope"></fa-icon>
                        </span>
                        <input type="email" id="email" name="email" class="form-control" autofocus="autofocus" autocomplete="none">
                    </div>
                </div>
                <div class="form-group mb-2">
                    <label for="password" class="form-label" placeholder="Enter your password here.">Password:</label>
                    <div class="input-group">
                        <span class="input-group-text icon-preview">
                            <fa-icon [icon]="faLock"></fa-icon>
                        </span>
                        <input type="text" id="password" name="password" class="form-control" autofocus="autofocus" autocomplete="none">
                        <span class="input-group-text icon-preview show-hide-password">
                            <fa-icon [icon]="faEyeSlash"></fa-icon>
                        </span>
                    </div>
                    <span class="instruction">Add 8 characters or more, lowercase letters, uppercase letters, numbers and symbols to  make the password really strong!</span>
                </div>
                <div>
                    <button type="button" class="btn login-btn">Create Account</button>
                </div>
            </form>
        </div>
    </div>
</div>
