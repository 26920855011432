<div class="auth-layout" style="background-image: url('/assets/auth/login-backtround.png')">
  <div class="card login-form-card">
    <div class="card-body">

      <img class="app-logo mb-3" [ngSrc]="'/assets/logo/lenovo-logo.png'" alt="app-logo-colorful.svg" height="43"
           width="202">
      <div class="mb-4">
        <h3 class="welcome-title">Forgot Password</h3>
        <p class="welcome-message">
          Enter your user name here. You will get an password reset code to the registered email.
        </p>
      </div>

      <form (ngSubmit)="toforgotPasswordSubmit()" #forgotPasswordForm="ngForm">
        <div class="form-group mb-2">
          <label for="username">User Name:</label>
          <div class="input-group">
            <span class="input-group-text">
              <i class="fa fa-user"></i>
            </span>
            <input type="text" id="username" class="form-control" placeholder="Enter Your User Name" name="username" [(ngModel)]="forgotPasswordModel.userName" required minlength="3" #username="ngModel" />
          </div>
          <div *ngIf="username.invalid && (username.dirty || username.touched)" class="text-danger">
            <div *ngIf="username.errors && username.errors['required']">
              Username is required.
            </div>
            <div *ngIf="username.errors && username.errors['minlength']">
              Username must be at least 3 characters long.
            </div>
          </div>
        </div>
        <div>
          <button type="submit" class="btn login-btn" [disabled]="forgotPasswordForm.invalid">
            Send Password Reset Code
          </button>
        </div>
      </form>
      

      <div>
        <p class="link">
          Do you remember your password?
          <a [routerLink]="'/login'" class="fw-medium">Login Now</a>
        </p>
      </div>
    </div>
  </div>
</div>
