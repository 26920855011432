import { Observable,BehaviorSubject, of, throwError, map, catchError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import  { Amplify,Auth} from  'aws-amplify';
import { environment } from 'src/environments/environment.development';
import { User,ConfirmModel, ForgotPasswordModel} from 'src/app/models/user';
import { HttpClient, HttpErrorResponse,HttpHeaders} from '@angular/common/http';
import { Country, Currency, Language, TimeZone } from '../models/comboModels';
import { Guid } from 'guid-typescript';
import jwt_decode from 'jwt-decode';
import { UserRole, InviteUser, InvitedUser } from 'src/app/modules/admin/models/invite-user';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authenticationSubject: BehaviorSubject<any>;
  private accesstoken :string="";
  private idtoken :string="";
  uName:string|null='';
    TimeZones:Array<TimeZone> =[];
    Id: string = Guid.EMPTY;
   confirmModel:ConfirmModel={} as ConfirmModel;
   tokenData:any;

  constructor(private router: Router, private http:HttpClient) {

    Amplify.configure({
          Auth:environment.cognito
    });
     this.authenticationSubject = new BehaviorSubject<boolean>(false);
  }

    adminSignUp(user:User):Observable<User> {
      return (this.http.post(environment.adminResourceUrl+"/account/Register", user).pipe(
        map(resp => {
          const userData:any = resp;
          return userData;
        })
      ));
    }

    registerUser(user:User): Observable<User> {
      return this.http.post<any>(environment.adminResourceUrl+"/account/Accounts", user)
        .pipe(map(resp => {
          console.log(resp)
          this.confirmModel.userName=resp.resultSet[0];
          this.confirmModel.userId=resp.resultSet[1];
          return resp;
        }));
    }


    erroHandler(error: HttpErrorResponse) {
      return throwError(error.message || 'server Error');
    }


    public forgotPassword(forgotPasswordModel:ForgotPasswordModel): Promise<any> {
      return Auth.forgotPassword(forgotPasswordModel.userName);
    }

    public forgotPasswordSubmit(forgotPasswordModel:ForgotPasswordModel): Promise<any> {
      return Auth.forgotPasswordSubmit(forgotPasswordModel.userName, forgotPasswordModel.code,forgotPasswordModel.password);
    }


    public confirmSignUp(confirmModel: ConfirmModel): Promise<any> {
      return Auth.confirmSignUp(confirmModel.userName, confirmModel.verificationCode);
    }

    public getUser(): Promise<any> {
      return Auth.currentUserInfo();
    }

    public updateUser(user: User): Promise<any> {
      return Auth.currentUserPoolUser()
      .then((cognitoUser: any) => {
        return Auth.updateUserAttributes(cognitoUser, user);
      });
    }

     public getSessionToken() {
      Auth.configure({
        oauth: AuthService
        })
        Auth.currentAuthenticatedUser()
        .then((user: any) => console.log(user))
        .catch((err: any) => console.log(err))
      }

      public isAuthenticated(): Promise<boolean> {
        if (this.authenticationSubject.value) {
          return Promise.resolve(true);
        } else {
          return this.getUser()
          .then((user: any) => {
            if (user) {
              return true;
            } else {
              return false;
            }
          }).catch(() => {
            return false;
          });
        }
      }

      public signOut(): Promise<any> {
        return Auth.signOut()
        .then(() => {
          localStorage.removeItem('token');
          localStorage.removeItem('idtoken');
          localStorage.removeItem("userId");
          this.authenticationSubject.next(false);
        });
      }



      public signIn(user: User): Promise<any> {
        return Auth.signIn(user.userName, user.password)
        .then((user:any) => {
          this.uName=user.username
         this.getSessionToken();
         this.accesstoken=user.getSignInUserSession().getAccessToken().getJwtToken();
         this.idtoken=user.getSignInUserSession().getIdToken().getJwtToken();
         this.setToken();
          this.authenticationSubject.next(true);
        });
      }

      setToken(): void {
    localStorage.clear();
    localStorage.setItem('token', this.accesstoken);
    localStorage.setItem('idtoken', this.idtoken);
    localStorage.setItem("userName",JSON.stringify(this.uName) )
    this.tokenData= jwt_decode(this.idtoken);
    localStorage.setItem("userId",this.tokenData['custom:UserId']);
  }



  getToken(): string | null {
    return localStorage.getItem('token');
  }

  InviteUser(invitedUser:InvitedUser): Observable<User> {
    return this.http.post<any>(environment.adminResourceUrl+"/account/Accounts/invite", invitedUser)
      .pipe(
        map(resp => {
          const invite:any = resp;
          return invite;
        })
      );
  }


  

}


