import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Route, Router } from '@angular/router';
import {faEye, faEyeSlash, faLock, faUser} from '@fortawesome/free-solid-svg-icons'
import { AuthService } from 'src/app/services/auth.service';
import {User} from 'src/app/models/user';
import { AlertifyService } from 'src/app/services/alertify.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
faLock=faLock;
loading: boolean;
  user: User;
loginForm=new FormGroup({
  email:new FormControl(''),
  password:new FormControl(''),

})
constructor(private authService: AuthService, private router:Router,private alertifyService:AlertifyService )
{
  this.loading = false;
  this.user = {} as User;
}


signIn(): void {
  this.loading = true;
  this.authService.signIn(this.user)
    .then(() => {
      this.router.navigate(['admin/dashboard']);
      this.loading = false;
      this.alertifyService.showSuccess('Sign in successful.');
    })
    .catch((error) => {
      this.loading = false;
      console.log(error)
      this.alertifyService.showError(error.message);
    });
}

  protected readonly faEyeSlash = faEyeSlash;
  protected readonly faEye = faEye;
  protected readonly faUser = faUser;
}
function jwt_decode(jwttoken: any) {
  throw new Error('Function not implemented.');
}

