<div class="error-code-page">
  <div class="error-content">
    <img class="app-logo mb-3" [ngSrc]="'/assets/logo/app-logo-colorful.svg'" alt="app-logo-colorful.svg" height="43"
         width="202">

    <img class="error-code-image" [ngSrc]="'/assets/error-codes/error-code-404.svg'" alt="page not found error"
         width="600" height="400">

    <div class="error-info-wrapper">
      <h1 class="error-title">
        Oops, Looks Like You're Lost!
      </h1>
      <p class="error-message">
        We're Sorry, but We Couldn't Find the Right Path.
      </p>
      <button [routerLink]="'/login'" class="btn btn-primary go-back-btn">
        <i class="fa fa-arrow-left me-2"></i> Go Back
      </button>
      <p class="link">
        Or
        <a [routerLink]="'https://support.lenovowarranty.com'"> Contact Support </a>
      </p>
    </div>
  </div>
</div>
