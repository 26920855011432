<div class="modal-header">
  <h6 class="modal-title" id="modal-basic-title">Customer Terms and Conditions Info</h6>
  <button (click)="closeModal()" aria-label="Close" class="btn-close" type="button"></button>
</div>
<div class="modal-body p-5">
  <h1 class="text-center">Terms and Conditions</h1>

  <h5>1. Introduction</h5>
  <p>Welcome to the Warranty Renewal System. This page contains the terms and conditions governing the use of our
    services. By accessing and using this system, you agree to comply with and be bound by these terms and
    conditions. If you do not agree with any part of these terms, please refrain from using our services.</p>

  <h5>2. Warranty Renewal</h5>
  <p>Our Warranty Renewal System allows customers to extend the warranty period for their purchased products. By
    using our service, you agree to the terms and conditions of our warranty renewal system. This includes the
    renewal process, applicable fees, and the necessary requirements for warranty extension.</p>

  <h5>3. User Responsibilities</h5>
  <p>As a user of our system, you are responsible for providing accurate and up-to-date information. You must ensure
    that the information you provide is truthful and complete. It is your responsibility to safeguard your login
    credentials and not share them with unauthorized individuals. If you suspect any unauthorized access to your
    account, you must notify us immediately.</p>

  <h5>4. Limitation of Liability</h5>
  <p>We make every effort to provide a secure and reliable system. However, we cannot guarantee that the system will
    be error-free or that access will be uninterrupted. By using our service, you agree that we are not liable for
    any direct, indirect, incidental, or consequential damages or losses that may result from the use of our system.
    Please review our detailed liability limitations in the full terms and conditions document.</p>

  <h5>5. Privacy Policy</h5>
  <p>Your privacy is important to us. Our privacy policy outlines how we collect, use, and protect your personal
    information. We are committed to safeguarding your data and using it only in ways that are consistent with your
    expectations and our privacy policy. By using our system, you agree to our privacy policy, and we encourage you
    to review it to understand how we handle your data.</p>

  <h5>6. Termination</h5>
  <p>We reserve the right to terminate or suspend your access to our system if you violate our terms and conditions.
    You may also terminate your account at any time by following the provided procedures. Upon termination, you may
    lose access to any associated data, and we will not be liable for any such loss. We may also retain and use your
    information as necessary to comply with legal obligations and resolve disputes. If you have questions about the
    termination process, please contact us.</p>

  <h5>7. Governing Law</h5>
  <p>These terms and conditions are governed by the laws of [Your Jurisdiction]. Any legal disputes arising from the
    use of our system will be resolved in the courts of [Your Jurisdiction]. By using our services, you consent to
    the exclusive jurisdiction of these courts for any legal proceedings related to our terms and conditions.</p>

  <h5>8. Changes to Terms and Conditions</h5>
  <p>We may update these terms and conditions from time to time. We will notify users of any changes by posting an
    updated version on our website. Your continued use of our system after the effective date of the updated terms
    implies your acceptance of the changes. We encourage you to review the terms and conditions periodically to stay
    informed about any modifications.</p>

  <h5>9. Contact Information</h5>
  <p>If you have any questions about our Terms and Conditions or need assistance, please contact us at the following
    address:</p>

  <address>
    Your Company Name<br>
    123 Main Street<br>
    City, State 12345<br>
    Email: info@example.com<br>
  </address>
</div>
<div class="modal-footer">
  <button (click)="closeModal()" class="btn btn-primary">Agree & Continue</button>
</div>
