import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})

export class RegAuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private route:ActivatedRoute) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const lastSegment = state.url.split('/').pop();

    if (lastSegment === "7eHg2fRtLpWq9aZ1xYcVbNpOq3jKdFgH")  {
      return true;
    } else {
      // Redirect to the login page if the user is not authenticated
      this.router.navigate(['/login']);
      return false;
    }
  }
}