import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/services/auth.service';
import {User} from 'src/app/models/user';
import {Country, Currency, Language, TimeZone} from 'src/app/models/comboModels';
import {comeOrGo} from "./animation";
import {faAngleLeft, faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import { AlertifyService } from 'src/app/services/alertify.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  animations: [comeOrGo]
})
export class SignUpComponent implements OnInit {

  
  protected readonly faAngleLeft = faAngleLeft;

  loading: boolean = false;
  isConfirm: boolean = false;
  user: User = {} as User;
  errorMessage: string = '';
  currentFormStep: number = 1;
  timeZoneList: TimeZone[] = [];
  countryList: Country[] = [];
  currencyList: Currency[] = [];
  languageList: Language[] = [];
  submitted = false;
  step1Completed=false;
  step2Completed=false;
  

  isAgreedToConditions: boolean = false;

  //signUpUser: UserRegistration = new UserRegistration("", "", "", "", "");


  constructor(private activatedRoute: ActivatedRoute,private router: Router, private authService: AuthService, private httpClient: HttpClient, private alertify:AlertifyService) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      const base64String = params['q'];
      if (base64String) {
        this.user.email = atob(base64String);
        console.log('Decoded email:', this.user.email);
      }
    });
  }

  submitRegistrationForm(registrationForm:any) {
    this.submitted = true;
    if ( registrationForm.valid) {
    this.user = registrationForm.value;
    this.authService.registerUser(this.user)
      .subscribe(
        (data:any) => {
          if(data.errors && data.errors.length>0){
            this.errorMessage=data.errors[0].description;
            this.alertify.showError(this.errorMessage);
          }
          else{
          this.setValues(data);
          }
        }
      );
    }
  }


  setValues(data: any) {
    this.user = data;
    this.router.navigate(['/confirm']);

  }


  goBackToPreviousSteps() {
    this.currentFormStep--;
    if (this.currentFormStep >= 2) {
      this.currentFormStep = 1;
    }
  }

  openNextStep(form:any) {
    if(this.isValidFirstStep(form) && (this.currentFormStep==1 ||
      (this.isValidSecondStep(form) && (this.currentFormStep==2))))
    {
    this.currentFormStep = Math.min(this.currentFormStep + 1, 2);
    }
  }
   isValidFirstStep(form:any)
  {
    return (form.controls.firstName.valid && form.controls.lastName.valid && form.controls.phoneNumber.valid);
  }

  isValidSecondStep(form:any)
  {
    return (form.controls.email.valid && form.controls.userName.valid &&
            form.controls.password.valid && form.controls.isAcceptedTerms.valid);
  }

  openPreviousStep() {
    this.currentFormStep = Math.max(this.currentFormStep - 1, 1);
  }


  protected readonly faEye = faEye;
  protected readonly faEyeSlash = faEyeSlash;
}

