<div class="auth-layout" style="background-image: url('/assets/auth/login-backtround.png')">
  <div class="card login-form-card">
    <div class="card-body">
      <img class="app-logo mb-3" [ngSrc]="'/assets/logo/lenovo-logo.png'" alt="app-logo-colorful.svg" height="60"
           width="202">
      <div class="mb-4">
        <h3 class="welcome-title">Create New Account</h3>
        <p class="welcome-message">Let’s create an new account. Please fill those information to continue. </p>
      </div>
      <div class="registration-form">
        <form #registrationForm="ngForm" class="needs-validation" novalidate (ngSubmit)="submitRegistrationForm(registrationForm)">
          <div class="section" [ngClass]="currentFormStep == 1 ? 'show' : 'hide'">
            <h5>General Information</h5>
            <div class="form-group mb-2">
              <label class="col-form-label">First Name:</label>
              <input required type="text" class="form-control" placeholder="Enter first name here."
                     [(ngModel)]="user.firstName" name="firstName" #firstName="ngModel" required pattern="^[a-zA-Z0-9_-]{3,15}$"/>
              <div class="error-message" [hidden]="firstName.valid || firstName.pristine">
                The first name you entered is invalid. Please provide a valid one.
              </div>
            </div>
            <div class="form-group mb-2">
              <label class="col-form-label">Last Name:</label>
              <input required type="text" class="form-control" placeholder="Enter last name here."
                     [(ngModel)]="user.lastName" name="lastName" #lastName="ngModel" required pattern="^[a-zA-Z0-9_-]{3,15}$"/>
              <div class="error-message" [hidden]="lastName.valid || lastName.pristine">
                The last name you entered is invalid. Please provide a valid one.
              </div>
            </div>
            <div class="form-group mb-2">
              <label class="col-form-label">Phone Number:</label>
              <input required type="tel" class="form-control" placeholder="Enter phone number here. (e.g., +94XXXXXXXXX)"
                     [(ngModel)]="user.phoneNumber" name="phoneNumber" #phoneNumber="ngModel"
                     required pattern="^(\+94)?\d{9}$"/>
              <div class="error-message" [hidden]="phoneNumber.valid || phoneNumber.pristine">
                  The phone number you entered is invalid. Please provide a valid one.
              </div>
            </div>
            <div class="action-btn-wrapper">
              <button type="button" class="btn btn-next" (click)="openNextStep(registrationForm)">Save & Open Credentials</button>
            </div>
          </div>

          <div class="section" [ngClass]="currentFormStep == 2 ? 'show' : 'hide'">
            <h5>User Credentials</h5>
            <div class="form-group mb-2">
              <label class="col-form-label">Invitee Email:</label>
              <input required type="email" class="form-control" [(ngModel)]="user.email" email="true" name="email" #email="ngModel" readonly/>
              <div class="error-message" [hidden]="email.valid || email.pristine">
                The email you entered is invalid. Please provide a valid one.
              </div>
            </div>
            <div class="form-group mb-2">
              <label class="col-form-label">Username:</label>
              <input required type="text" class="form-control" [(ngModel)]="user.userName" name="userName" #userName="ngModel" required pattern="^[a-zA-Z0-9_-]{8,15}$"/>
              <div class="error-message" [hidden]="userName.valid || userName.pristine">
                The username you entered is invalid. Please provide a valid one.
              </div>
            </div>
            <div class="form-group mb-2">
              <label for="password-input" class="form-label">Password:</label>
              <div class="input-group">
                <input required [type]="user.showPassword ? 'text' : 'password'" id="password-input" required pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%?&])[A-Za-z\d$@$!%?&]{8,}$" class="form-control" placeholder="Enter password here." [(ngModel)]="user.password" name="password" #password="ngModel"/>
                <span class="input-group-text show-hide-password" (click)="user.showPassword = !user.showPassword">
                  <fa-icon [icon]="user.showPassword ? faEye : faEyeSlash"></fa-icon>
                </span>
              </div>
              <div class="error-message" [hidden]="password.valid || password.pristine">
                The password you entered is invalid. Please provide a valid one.
              </div>
            </div>
            <div class="form-group mb-2">
              <label for="re-enter-password-input" class="form-label">Re-Enter Password:</label>
              <div class="input-group">
                <input [type]="user.showPassword ? 'text' : 'password'" id="re-enter-password-input" name="confirmPassword" required pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%?&])[A-Za-z\d$@$!%?&]{8,}$" placeholder="Enter the same password again here." class="form-control"  [(ngModel)]="user.confirmPassword" name="confirmPassword" #confirmPassword="ngModel"/>
                <span class="input-group-text show-hide-password" (click)="user.showPassword = !user.showPassword">
                  <fa-icon [icon]="user.showPassword ? faEye : faEyeSlash"></fa-icon>
                </span>
              </div>
              <div class="error-message" [hidden]="(confirmPassword.valid || confirmPassword.pristine) && user.password==user.confirmPassword">
                The password you entered is invalid. Please provide a valid one.
              </div>
            </div>
            <div class="form-group mb-2">
              <div class="form-group form-check">
                <input type="checkbox" class="form-check-input" id="flexCheckDefault" [(ngModel)]="user.isAcceptedTerms" name="isAcceptedTerms" #isAcceptedTerms="ngModel"/>
                <div class="error-message" [hidden]="(isAcceptedTerms.valid || isAcceptedTerms.pristine) && user.isAcceptedTerms">
                  Please accept the terms and conditions.
                </div>
              </div>
              <p class="link privacy-policy-link text-start">
                <i class="fa fa-user me-2"></i> View our
                <a routerLink="/privacy-policy">Privacy Policy.</a>
              </p>
            </div>
            <div class="action-btn-wrapper">
              <button type="button" class="btn btn-go-back" (click)="openPreviousStep()">
                <fa-icon [icon]="faAngleLeft"></fa-icon>
              </button>
              <button type="submit" class="btn login-btn" [disabled]="loading">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                      *ngIf="loading"></span>
              Complete
            </button>
            </div>
          </div>
          <div class="sign-up-step-message">
            <p class="mb-0 fw-normal">
              Account creation process is almost complete!
              <br> press continue for final configurations.
            </p>
          </div>
        </form>
      </div>

      <div class="bottom-actions">
        <p class="link">
          Already have an account?
          <a routerLink="/login">Go to Login.</a>
        </p>
      </div>
    </div>
  </div>
</div>


