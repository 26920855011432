import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-attachment-preview',
  templateUrl: './attachment-preview.component.html',
  styleUrls: ['./attachment-preview.component.scss']
})
export class AttachmentPreviewComponent implements OnInit {
  @Input() modalData: { attachment: string, title: string, } | undefined = undefined;
  previewImage: any;
  protected readonly close = close;

  constructor(
    private activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.previewImage = this.getSafeImageSource(this.modalData?.attachment);
  }

  closeModal() {
    this.activeModal.close();
  }

  getSafeImageSource(base64Image: any): SafeResourceUrl {
    const imageSource = 'data:image/png;base64,' + base64Image;
    return this.sanitizer.bypassSecurityTrustResourceUrl(imageSource);
  }
}

